import { useContext } from 'react';
import { Navigate, Route, Routes } from 'react-router-dom';
import { ChatModelsList } from 'src/components/chatModel';
import { CompaniesList } from 'src/components/company';
import { AuthContext } from 'src/components/context.component';
import { Dashboard } from 'src/components/dashboard';
import { btnLinks } from 'src/components/left-menu.component';
import { ProxyList } from 'src/components/proxy';
import { EditMyProfile, UsersList } from 'src/components/user';
import { RolesEnum } from 'src/types';
import { PageNotFound } from 'src/ui';

export const AuthRoutes = () => {
  const { currentUser } = useContext(AuthContext);

  return (
    <Routes>
      <Route path="/" element={<Navigate to={btnLinks.dashboard} />} />
      <Route path={btnLinks.dashboard} element={<Dashboard />} />
      <Route path={btnLinks.companies} element={<CompaniesList />} />
      <Route path={btnLinks.people} element={<UsersList />} />
      <Route path={btnLinks.chatModels} element={<ChatModelsList />} />
      {currentUser && currentUser.role === RolesEnum.SUPER_ADMIN && (
        <Route path={btnLinks.proxies} element={<ProxyList />} />
      )}
      <Route path={btnLinks.myProfile} element={<EditMyProfile />} />
      <Route path="*" element={<PageNotFound />} />
    </Routes>
  );
};
